<template>
  <ul class="item-filters-list">
    <li
      v-for="childCategory in model.items"
      :key="childCategory.slug"
    >
      <router-link
        v-if="categoryIsChecked(childCategory.slug)"
        :to="{ name: 'filters', query: { filters: childCategory.slug } }"
        class="active"
        :class="{
          'c-tag': !childCategory.logo,
          'c-tag--dealer': childCategory.logo,
          'is-loading': loading
        }"
        @click="onRemoveCategoryClick(childCategory)"
        @keyup.enter="onRemoveCategoryClick(childCategory)"
      >
        <img
          v-if="childCategory.logo"
          :src="childCategory.logo"
          :alt="childCategory.name"
          width="85"
          height="30"
        >
        <template v-else>
          {{ childCategory.name }}
        </template>
      </router-link>
      <router-link
        v-else
        :to="{ name: 'filters', query: { filters: childCategory.slug } }"
        :class="{
          'c-tag': !childCategory.logo,
          'c-tag--dealer': childCategory.logo,
          'is-loading': loading
        }"
        @click="onAddCategoryClick(childCategory)"
        @keyup.enter="onAddCategoryClick(childCategory)"
      >
        <img
          v-if="childCategory.logo"
          :src="childCategory.logo"
          :alt="childCategory.name"
          width="85"
          height="30"
        >
        <template v-else>
          {{ childCategory.name }}
        </template>
      </router-link>
    </li>
  </ul>
</template>

<script>
import searchQueryState from '@/composition/search/searchQuery'
import searchFactory from '@/composition/search/searchFactory'
import gtmUtil from '@/utils/gtm-util'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const placement = 'filters panel'
    const {
      activeCategories,
      addCategory,
      removeCategory
    } = searchQueryState.searchQueryUse()

    const { loading } = searchFactory.useSearchResult()

    const categoryIsChecked = (categorySlug) => activeCategories.value.length > 0
        && activeCategories.value.some((category) => category.slug === categorySlug)

    const onAddCategoryClick = (category) => {
      context.emit('categoryClick')

      gtmUtil.selectTag(category.name, props.model.name, placement)
      addCategory(category)
    }

    const onRemoveCategoryClick = (category) => {
      context.emit('categoryClick')

      removeCategory(category)
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeTag(category.name, currentTags.join(', '), placement)
    }

    return {
      categoryIsChecked,

      onAddCategoryClick,
      onRemoveCategoryClick,
      loading
    }
  }
}
</script>
