<template>
  <div
    class="c-search-filters"
    :class="{ 'show-filters': filtersAreVisible }"
  >
    <div class="u-flex u-flex-justify-space-between u-gap--s04">
      <button
        class="c-button u-text-nowrap u-hidden@desktop"
        @click="showFilters"
      >
        <CI
          :icon="IconFilter"
          class="c-base-icon"
          size="16"
        />
        {{ translations.openFiltersText }}
      </button>
      <SearchSorting />
    </div>
    <div
      class="item-filters-wrap"
      @click.stop
    >
      <div
        ref="filtersContainer"
        class="item-filters-inner"
      >
        <div class="item-filters-header">
          <button
            class="c-button--close"
            @click="hideFilters"
          >
            <Icon
              :icon="IconClose"
              :size="16"
            />
            <span class="u-hide-on-screenonly">
              {{ translations.closeFiltersText }}
            </span>
          </button>
          <strong class="h3 u-mb--0">
            {{ translations.filtersText }}
          </strong>
        </div>
        <search-filters-category
          v-for="(category, index) in categories"
          :key="category.slug"
          :category="category"
          :is-expanded-by-default="index < expandedFilters"
        />
        <div class="item-filters-footer">
          <button
            class="c-button c-button--solid"
            @click="hideFilters"
          >
            {{ totalCountText }}
          </button>
          <button
            class="c-button"
            @click="removeAllFilters"
          >
            {{ translations.clearAllText }}
          </button>
        </div>
      </div>
      <div
        class="item-filters-overlay"
        @click="hideFilters"
        @keyup.esc="hideFilters"
      />
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
import { useMq } from 'vue3-mq'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import SearchFiltersCategory from '@/components/search/SearchFiltersCategory.vue'
import SearchSorting from '@/components/search/SearchSorting.vue'
import Icon from '@/components/Icon.vue'
import CI from '@/components/CI.vue'
import searchConfig from '@/composition/search/searchConfig'
import searchQueryState from '@/composition/search/searchQuery'
import gtmUtil from '@/utils/gtm-util'
import { IconClose, IconFilter } from '@/utils/icons'

export default {
  components: {
    SearchSorting,
    CI,
    Icon,
    SearchFiltersCategory
  },
  setup() {
    const placement = 'filters panel'
    const {
      searchConfiguration,
      baseUrl
    } = searchConfig.searchConfigurationUse()
    const {
      activeCategories,
      searchResult,

      resetAllFilters
    } = searchQueryState.searchQueryUse()
    const filtersAreVisible = ref(false)
    const filtersContainer = ref()
    const translations = computed(() => searchConfiguration.translations)
    const isMobile = computed(() => useMq().current === 'mobile' || useMq().current === 'tablet')

    const inactiveFilters = computed(() => searchConfiguration.categories.filter((x) => !activeCategories.value.includes(x)))
    const totalCountText = computed(() => (translations.value
      ? translations.value.totalCountMessage.replace('{0}', searchResult.totalCount)
      : ''
    ))

    const showFilters = () => {
      filtersAreVisible.value = true
      disableBodyScroll(filtersContainer.value)
      gtmUtil.openFilterMenu()
    }

    const hideFilters = () => {
      filtersAreVisible.value = false
      enableBodyScroll(filtersContainer.value)
      gtmUtil.closeFilterMenu()
    }

    const removeAllFilters = () => {
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeAllTags(currentTags.join(', '), placement)
      resetAllFilters()
    }

    return {
      baseUrl,
      inactiveFilters,
      categories: computed(() => searchConfiguration.categories),
      expandedFilters: 2,
      isMobile,
      translations,
      IconClose,
      IconFilter,
      filtersAreVisible,
      filtersContainer,
      totalCountText,

      showFilters,
      hideFilters,
      removeAllFilters
    }
  }
}
</script>
