<template>
  <div
    v-if="activeTerms.length"
    class="c-active-tags"
  >
    <button
      v-for="(term, index) in activeTerms"
      :key="index"
      class="c-tag active"
      @click="removeSearchTerm(term)"
    >
      {{ term }}
      <Icon
        :icon="iconClose"
      />
    </button>

    <button
      class="c-button-link"
      @click="removeAllTags"
    >
      {{ translations.clearAllText }}
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'
import iconClose from '@/assets/img/icons/x-mark.svg'
import searchQueryState from '@/composition/favorites/favoritesSearchQuery'
import searchConfig from '@/composition/favorites/favoritesSearchConfig'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Icon
  },
  setup() {
    const placement = 'active tags'
    const { searchConfiguration } = searchConfig.searchConfigurationUse()

    const {
      activeTerms,

      removeTerm,
      resetAllFilters
    } = searchQueryState.searchQueryUse()

    const removeSearchTerm = (term) => {
      removeTerm(term)
      gtmUtil.removeTag(term, activeTerms.value.join(', '), placement)
    }

    const removeAllTags = () => {
      gtmUtil.removeAllTags(activeTerms.value.join(', '), placement)
      resetAllFilters()
    }

    return {
      iconClose,
      activeTerms,
      translations: computed(() => searchConfiguration.translations),

      removeSearchTerm,
      removeAllTags
    }
  }
}
</script>
