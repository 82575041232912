<template>
  <div class="Form__Element FormChoice FormChoice--Checkbox">
    <input
      :id="model.elementGuid"
      type="hidden"
      class="Form__Element FormHidden FormHideInSummarized"
      data-f-type="hidden"
      :name="model.elementName"
      :value="submissionData"
    >
    <template v-if="model.permissionBlock.useSeparatePermissions">
      <div>
        <span v-html="model.permissionBlock.topPermissionHtml" />
      </div>
      <div class="form-checkbox">
        <label
          :for="`${model.elementGuid}-email-and-sms-permission`"
        >
          <input
            :id="`${model.elementGuid}-email-and-sms-permission`"
            v-model="emailAndSmsPermissionAccepted"
            type="checkbox"
            name="emailAndSmsPermission"
            :required="!phonePermissionAccepted"
          >
          {{ model.permissionBlock.emailAndSmsPermissionLabel }}
        </label>
      </div>
      <div v-if="model.permissionBlock.enablePhonePermission">
        <label
          :for="`${model.elementGuid}-phone-permission`"
        >
          <input
            :id="`${model.elementGuid}-phone-permission`"
            v-model="phonePermissionAccepted"
            type="checkbox"
            name="phonePermission"
            :required="!emailAndSmsPermissionAccepted"
          >
          {{ model.permissionBlock.phonePermissionLabel }}
        </label>
      </div>
      <div class="form__row subscribe-form__permission">
        <span v-html="model.permissionBlock.bottomPermissionHtml" />
      </div>
    </template>
    <template v-else>
      <label
        :for="`${model.elementGuid}-permission-terms-of-use`"
        class="FormChoice__Label"
      >
        <input
          :id="`${model.elementGuid}-permission-terms-of-use`"
          v-model="termsAccepted"
          name="termsAccepted"
          class="FormChoice__Input FormChoice__Input--Checkbox"
          type="checkbox"
        >
        <span
          class="FormChoice__Caption"
          v-html="model.permissionBlock.disclaimerHtml"
        />
        <span class="FormChoice__Indicator" />
      </label>
    </template>
    <div
      v-if="errorMessage.length"
      class="Form__Element__ValidationError"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const errorMessage = ref('')
    const termsAccepted = ref(false)
    const phonePermissionAccepted = ref(false)
    const emailAndSmsPermissionAccepted = ref(false)
    const submissionData = computed(() => JSON.stringify({
      useSeparatePermissions: props.model.permissionBlock.useSeparatePermissions,
      enablePhonePermission: props.model.permissionBlock.enablePhonePermission,
      termsAccepted: termsAccepted.value,
      phonePermissionAccepted: phonePermissionAccepted.value,
      emailAndSmsPermissionAccepted: emailAndSmsPermissionAccepted.value,
      permissionVersion: props.model.permissionBlock.permissionVersion,
      url: window.location.href
    }))

    onMounted(() => {
      const forms = window.epi && window.epi.EPiServer && window.epi.EPiServer.Forms
      if (!forms) {
        return
      }
      $.extend(true, forms, {
        Validators: {
          'Website.Features.Blocks.Components.PermissionElementBlock.PermissionValidator': (_, __, validator) => {
            let isValid
            if (props.model.permissionBlock.useSeparatePermissions) {
              isValid = emailAndSmsPermissionAccepted.value
              if (props.model.permissionBlock.enablePhonePermission) {
                isValid = isValid && phonePermissionAccepted.value
              }
            } else {
              isValid = termsAccepted.value
            }

            errorMessage.value = !isValid ? validator.model.message : ''

            return {
              isValid
            }
          }
        }
      })
    })

    return {
      termsAccepted,
      phonePermissionAccepted,
      emailAndSmsPermissionAccepted,
      submissionData,
      errorMessage
    }
  }
}
</script>
